import type { InjectionKey, Ref } from 'vue'

export const uiHeaderContextKey = Symbol('uiHeader') as InjectionKey<{
  el: Ref<HTMLElement | null>
  isHeaderVisible: Ref<boolean>
  headerHeight: Ref<number>
  height: Ref<number>
}>

export function useUiHeader() {
  const el = ref<HTMLElement | null>(null)
  const isHeaderVisible = ref(false)
  const headerHeight = ref(0)

  const { y } = useWindowScroll()
  const { height } = useElementSize(el)
  const _headerHeight = computed(() => height.value + 40) // Additional 40px for the top bar

  watch(y, (newY, oldY = 0) => {
    isHeaderVisible.value = newY < oldY || newY <= _headerHeight.value
  }, { immediate: true })

  watch(height, (newHeight) => {
    headerHeight.value = newHeight + 40
  })

  provide(uiHeaderContextKey, { el, isHeaderVisible, headerHeight, height })

  return { el, isHeaderVisible, headerHeight, height }
}
